<template>
  <div
    class="flex flex-col min-h-screen bg-gradient-to-b from-gray-200 to-green-200"
  >
    <router-view />
    <footer class="relative flex-grow">
      <div class="absolute bottom-3 w-full">
        <div class="max-w-sm mx-auto">
          <p class="text-center text-xs font-semibold text-gray-500 uppercase">
            &mdash; &nbsp;Developed By
            <a
              href="https://www.linkedin.com/in/fraser-lennon-95aa59112/"
              target="_blank"
              class="font-bold"
              >Fraser Lennon</a
            >&nbsp; &mdash;
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* override browser default */
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* use viewport-relative units to cover page fully */
body {
  height: 100vh;
  width: 100vw;
}

/* include border and padding in element width and height */
* {
  box-sizing: border-box;
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
</style>

<template>
  <div
    class="cursor-pointer max-w-md md:max-w-2xl mx-auto bg-white rounded-xl shadow-md hover:shadow-xl overflow-hidden"
  >
    <router-link :to="link">
      <div class="md:flex">
        <div class="md:flex-shrink-0">
          <img
            v-if="artistThumb"
            class="h-60 w-full object-cover md:w-40 md:h-40"
            :src="artistThumb"
            :alt="artist.name"
          />
          <img
            v-else
            class="h-60 w-full object-cover md:w-40 md:h-40"
            src="../assets/placeholder.jpg"
            :alt="artist.name"
          />
        </div>
        <div class="p-8 my-auto">
          <div
            class="ml-0.5 uppercase tracking-wide text-lg text-green-500 font-bold"
          >
            {{ artist.name }}
          </div>
          <div v-if="genres.length" class="flex flex-wrap mt-2">
            <span
              v-for="(genre, idx) in genres"
              :key="idx"
              class="m-0.5 whitespace-nowrap m-0 px-2 text-sm text-white border-2 rounded-md border-gray-600 bg-gray-900"
              >{{ genre }}</span
            >
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ArtistRow",

  props: {
    artist: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    genres() {
      return this.artist.genres;
    },

    artistThumb() {
      const images = this.artist.images;
      return images.md || images.lg || images.sm;
    },

    link() {
      return `/artist/${this.artist.id}`;
    },
  },
};
</script>

<style>
</style>
import axios from 'axios';
import { searchUrl, artistUrl } from './endpoints';

export const searchArtists = async (searchTerm) => {
    const request = {
        method: 'GET',
        url: `${searchUrl}?q=${searchTerm}`
    }
    const response = await axios(request);
    return response.data;
};

export const fetchArtist = async (artistId) => {
    const request = {
        method: 'GET',
        url: `${artistUrl}/${artistId}`
    }
    const response = await axios(request);
    return response.data;
};
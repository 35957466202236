<template>
  <div class="container py-20 mx-auto" :class="hasArtists ? 'py-20' : 'py-40'">
    <div class="mx-auto mb-16 w-7/12 md:w-5/12 lg:w-3/12">
      <img alt="Vue logo" src="../assets/Spotify_Logo.png" />
      <h1
        class="text-xl sm:text-2xl md:text-3xl font-bold flex justify-end -mt-3 pr-1 text-green-500"
      >
        Profiler
      </h1>
    </div>
    <form
      class="w-11/12 md:w-8/12 lg:w-4/12 mb-10 mx-auto"
      @submit.prevent="updateSearch"
      action="submit"
    >
      <input
        v-model="searchTerm"
        type="search"
        name="search-input"
        id="search-input"
        ref="searchInput"
        placeholder="Find an artist..."
        class="text-center text-gray-600 md:text-2xl text-xl pl-3 font-bold border border-gray-300 shadow-sm rounded-md w-full h-12 sm:h-14 focus:outline-none focus:border-2"
      />
    </form>
    <div class="space-y-6 mx-2">
      <Artist-Row v-for="artist in artists" :key="artist.id" :artist="artist" />
    </div>
  </div>
</template>


<script>
import ArtistRow from "@/components/ArtistRow";
import { searchArtists } from "@/api/api";
export default {
  name: "Home",

  data() {
    return {
      artists: [],
      searchTerm: "",
    };
  },

  mounted() {
    if (this.$route.query['search-term']) {
      this.searchTerm = this.$route.query['search-term'];
      this.search();
    }
  },

  methods: {
    async search() {
      this.artists = this.searchTerm ? await searchArtists(this.searchTerm) : [];
    },

    updateSearch() {
      this.$router.push({
        path: "/",
        query: {
          "search-term": this.searchTerm,
        },
      });
      this.$refs.searchInput.blur();
    },
  },

  computed: {
    hasArtists() {
      return this.artists.length;
    },
  },

  watch: {
    $route() {
      this.search();
    },
  },

  components: {
    ArtistRow,
  },
};
</script>

<style>
</style>